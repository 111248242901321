import React,{useState, useEffect} from 'react';
import './Contact.scss'
import {Grid,Container, Box} from '@material-ui/core';

export default function Contact(){

    return <div className="contact">
        <Container style={{height:'100%'}}>
            <Box display="flex" height="100%" alignItems="center">
                <Box>Copyright © 2021 Jimmy Nguyen</Box>
                <Box flexGrow={1}/>
                <Box>
                    <a href="https://www.linkedin.com/in/jimmy-nguyen-1a56b320b/">Linkedin</a>
                    <a href="mailto:tbn.techno@gmail.com">Email</a>
                </Box>
            </Box>
        </Container>
    </div>
}