import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MineSweeper  from './pages/projects/MineSweeper'
import Main         from './pages/Main'
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <Switch>
          <Route path='/minesweeper' exact  component = {MineSweeper}/>
          <Route path='/'            exact  component = {Main}/>
          {/*<ProtectedRoute   path = '/main'     exact component = {Main}/>
          <ProtectedRoute   path = '/signout'  exact component = {SignOut}/>
          <Route                                     component = {Home} /> */}
        </Switch>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
