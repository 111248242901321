import React,{useState, useEffect} from 'react';
import {Container,Box} from '@material-ui/core';
import { Link, animateScroll, scroller } from 'react-scroll'

import './Nav.scss';
export default function Nav(){
    const [scroll, setScroll] = useState(false)
    const [burger, setBurger] = useState(false)
    const linkOptions = {
        spy:true, smooth:true, duration:500, activeClass:"nav-item-active"
    }

    useEffect(()=>{
        window.addEventListener("scroll", ()=>setScroll(window.scrollY > 3));
        
        return ()=> window.removeEventListener('scroll', ()=>{});
    },[])

    const isScroll = ()=> scroll ? "scroll": "";
    const isToggle = ()=> burger ? "toggle": "";
    const handleToggle = ()=>setBurger(!burger)
    // const handleScrollToTop = ()=> animateScroll.scrollToTop()
    // const handleScroll      = (id)=> scroller.scrollTo(id, {duration: 800,delay: 0,smooth: 'easeInOutQuart', spy:true})
 
    return <div className={`nav ${isScroll()}`.trim()}>
        <Container style={{height:"100%"}}>
            <Box display="flex" alignItems="center" height="100%">
                <Box className="nav-item logo"><Link {...linkOptions} to="home">Jimmy</Link></Box>
                <Box flexGrow={1}/>
                <Box className={`nav-item burger ${isToggle()}`.trim()} onClick={handleToggle}>
                    <div className="burger-line line1"/>
                    <div className="burger-line line2"/>
                    <div className="burger-line line3"/>
                </Box>
                <Box className={`nav-items ${isToggle()}`.trim()} display="flex" alignItems="center">
                    <Box className="nav-item"><Link {...linkOptions} to="home"      >Home</Link></Box>
                    <Box className="nav-item"><Link {...linkOptions} to="about"     >About</Link></Box>
                    <Box className="nav-item"><Link {...linkOptions} to="projects"  >Projects</Link></Box>
                    <Box className="nav-item"><Link {...linkOptions} to="contact"   >Contact</Link></Box>
                </Box>
            </Box>
        </Container>
    </div>
}