import React,{useState, useEffect} from 'react';
import {Grid,Container, Box} from '@material-ui/core';
import imgChatApp1  from './../../img/chat-app-1.png';
import imgChatApp2  from './../../img/chat-app-2.png';
import minesweeper1 from './../../img/minesweeper1.png';
import minesweeper2 from './../../img/minesweeper2.png';
import gymesis1     from './../../img/gymesis-1.png';
import gymesis2     from './../../img/gymesis-2.png';
import gymesis3     from './../../img/gymesis-3.png';
import Fade from 'react-reveal/Fade';
import CloseIcon from '@material-ui/icons/Close';

import './Projects.scss'
export default function Projects(){
    const [currentImage, setCurrentImage] = useState(0);
    const [isModalOpen, setIsModalOpen]   = useState(false);

    const gallery = [
        imgChatApp1,
        imgChatApp2,
        minesweeper1,
        minesweeper2,
        gymesis1,
        gymesis2,
        gymesis3
    ]
    const handleCloseModal = (e)=> {
        e.preventDefault();
        if(e.target === e.currentTarget) {
            setIsModalOpen(false)
        }
    }

    const handleOpenModel = (i)=> { setCurrentImage(i); setIsModalOpen(true) }

    return <div className="projects">
        <Container>
            <Box className="projects-header">Projects</Box>
            {/* PROJECT-1 */}
            <Fade left>
                <div className="projects-items">
                    <Box className="project project-1" display="flex" flexDirection="column" alignItems="stretch">
                        <Box className="images" flexGrow={1} display="flex">
                            <img src={gallery[0]} style={{width:'20%'}} onClick={()=>handleOpenModel(0)}/>
                            <img src={gallery[1]} style={{width:'80%'}} onClick={()=>handleOpenModel(1)}/>
                        </Box>
                        <div className="info">
                            <div className="title">TBNChatapp</div>
                            <div className="description">TBNChatapp is a chat platform that provides a real-time communication experience on top of a modern design UI.</div>
                            <Grid container>
                                <Grid xs={12} md={6} item className='hashtags'>#socket-io #reactjs #nodejs #mysql #bootstrap</Grid>
                                <Grid xs={12} md={6} item>
                                    <Box className="links">
                                        {/* <a className="hibi">How I Built It</a> */}
                                        <a className="demo" href="https://tbn-chatapp-client.herokuapp.com/">Demo</a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </div>
            </Fade>

            {/* PROJECT-2 */}
            <Fade right>
                <div className="projects-items">
                    <Box className="project project-1" display="flex" flexDirection="column" alignItems="stretch">
                        <Box className="images" flexGrow={1} display="flex">
                            <img src={gallery[2]} style={{width:'50%'}} onClick={()=>handleOpenModel(2)}/>
                            <img src={gallery[3]} style={{width:'50%'}} onClick={()=>handleOpenModel(3)}/>
                        </Box>
                        <div className="info">
                            <div className="title">Minesweeper AI</div>
                            <div className="description">Minesweeper AI is a minesweeper game that is assisted with a knowledge-based system AI.</div>
                            <Grid container>
                                <Grid xs={12} md={6} item className='hashtags'>#typescript #reactjs #material-ui</Grid>
                                <Grid xs={12} md={6} item>
                                    <Box className="links">
                                        {/* <a className="hibi">How I Built It</a> */}
                                        <a className="demo" href='/minesweeper'>Demo</a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </div>
            </Fade>
            
            {/* PROJECT-3 */}
            <Fade left>
                <div className="projects-items">
                    <Box className="project project-1" display="flex" flexDirection="column" alignItems="stretch">
                        <Box className="images" flexGrow={1} display="flex">
                            <img src={gallery[6]} style={{width:'33.33%'}} onClick={()=>handleOpenModel(6)}/>  
                            <img src={gallery[4]} style={{width:'33.33%'}} onClick={()=>handleOpenModel(4)}/>
                            <img src={gallery[5]} style={{width:'33.34%'}} onClick={()=>handleOpenModel(5)}/>  
                        </Box>
                        <div className="info">
                            <div className="title">Gymesis</div>
                            <div className="description">Gymesis is a mobile application that monitors the progress and new workout ideas for users. Gymesis app provides a friendly and convenient experience for the new gym-goers who are new to the gym</div>
                            <Grid container>
                                <Grid xs={12} md={6} item className='hashtags'>#react-native #material-ui #firebase</Grid>
                                <Grid xs={12} md={6} item>
                                    <Box className="links">
                                        <label className='onprogress'>On Progress</label>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </div>
            </Fade>
        </Container>
        {isModalOpen ? <div className="imgModal" onClick={(e)=>handleCloseModal(e)}>
                <img src={gallery[currentImage]}/>
                <CloseIcon className='close' onClick={()=>setIsModalOpen(false)}/>
            </div>:""
        }
        
    </div>
}