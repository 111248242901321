
import "./98.scss";
import React from 'react';

export default function Alert({confirm, setConfirm}){
    const handleConfirm = ()=>{
        confirm.action()
        setConfirm({})
    }
    const handleCancel = ()=>{
        setConfirm({})
    }
    return <div className="alert-wrapper" style={{display: !confirm.state? "none": ""}}>
        <div style={{ width: 300 }} className="window">
            <div className="title-bar">
                <div className="title-bar-text">{confirm.title}</div>
                <div className="title-bar-controls">
                <button aria-label="Close" onClick={handleCancel}/>
                </div>
            </div>
            <div className="window-body">
                <div>{confirm.message}</div>
                <div className="field-row" style={{ justifyContent: "flex-end" }}>
                    <button onClick={handleConfirm}>OK</button>
                    <button onClick={handleCancel}>Cancel</button>
                </div>
            </div>
        </div>
    </div>
}