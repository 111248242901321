import React,{useState, useEffect} from 'react';
import { Grid, Box, Container }   from '@material-ui/core';

import { scroller } from 'react-scroll'
import Fade from 'react-reveal/Fade';
// import Typing from 'react-typing-animation';
import './Home.scss';
// import Nav      from '../../components/Nav/Nav'
export default function Home(){
    const linkOptions = {
        spy:true, smooth:true, duration:500, activeClass:"nav-item-active"
    }
    return <div className='home'>
        {/* <Box height={120} width={'100%'}/> */}
        <Container style={{height:'100%'}}>
            <Grid container style={{height:'100%'}}>
                <Box xs={12} md display={{ xs: 'block', md: 'none' }} width={"100%"} height={100}/>
                <Grid xs={12} md={6} item>
                    <Box height={"100%"} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Fade left>
                            <div>
                                <div className="portrait">
                                </div>
                            </div>
                        </Fade>
                    </Box>
                </Grid>
                <Grid xs={12} md={6} item>
                    <Box height={"100%"} display="flex" flexDirection="column" justifyContent="center">
                        <Fade right cascade>
                            <div className="introduction">
                                <div className="line line-1">Hi, my name is <span className="full-name">Jimmy Nguyen</span></div>
                                <div className="line line-2">I build cool stuffs for others</div>
                                <div className="line line-3">I am a recent graduate from Cal State Long Beach and an enthusiastic software developer who loves to be challenged as well as helping others build their dream projects.</div>
                                <div className="line line-4">
                                    <button onClick={()=>scroller.scrollTo('about', linkOptions)}>More about me</button>
                                </div>
                            </div>
                        </Fade>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        {/* <div className="arrow-down" onClick={()=>scroller.scrollTo('about', linkOptions)}>
            <KeyboardArrowDown fontSize="large"/>
        </div> */}
    </div>
}