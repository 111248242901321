import React,{useState, useEffect} from 'react';
import {Box, Container} from '@material-ui/core';
import './About.scss';

export default function About(){
    return <div className="about">
        <Container style={{overflow:'auto'}}>
            <Box className="about-header" style={{textAlign:'center'}}>About Me</Box>
            <div className="code-panel">
                <div className="title-bar">
                    <Box className="dots" display="flex" flexDirection="row" alignItems="center">
                        <span className='dot red'/>
                        <span className='dot yellow'/>
                        <span className='dot gray'/>
                    </Box>
                    <label className="title">about.js</label>
                </div>
                <div className="code">
                    <Box display="flex" flexDirection="row">
                        <Box className="indexes" display="flex" flexDirection="column" alignItems="flex-end">
                            {(new Array(37).fill()).map((l,i)=><span key={i}>{i+1}</span>)}
                        </Box>
                        <pre>
                            <code>
                                <span className='keyword'>const</span> <span className='variable'>jimmy</span> = {`{`}
                                {`\n\t`}<span className='property'>location</span>:{`  `}<span className='string'>"Orange County, CA"</span>,
                                {`\n\t`}<span className='property'>education</span>:{` `}<span className='string'>"Bachelor of Computer Science"</span>,
                                {`\n\t`}<span className='property'>graduated</span>:{` `}<span className='string'>"December 2020"</span>,
                                {`\n\t`}<span className='property'>gpa</span>:{`        `}<span className='number'>3.79</span>,

                                {`\n\t`}<span className='property'>languages</span>:{`   `}{`{`}
                                {`\n\t`}{`\t\t`}<span className='string'>'python': 'strong'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'javascript': 'strong'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'html/css': 'strong'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'mysql': 'strong'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'java': 'medium'</span>
                                {`\n\t`}{`\t\t`}<span className='string'>'c++': 'medium'</span>
                                {`\n\t`}{`},`}

                                {`\n\t`}<span className='property'>frameworks</span>:{`  `}{`{`}
                                {`\n\t`}{`\t\t`}<span className='string'>'reactJS': 'strong'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'laravel': 'medium'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'spring': 'medium'</span>
                                {`\n\t`}{`},`}

                                {`\n\t`}<span className='property'>libraries</span>:{`   `}{`{`}
                                {`\n\t`}{`\t\t`}<span className='string'>'bootstrap': 'strong'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'materialUI': 'strong'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'jQuery': 'strong'</span>
                                {`\n\t`}{`},`}

                                {`\n\t`}<span className='property'>tools</span>:{`       `}{`[`}
                                {`\n\t`}{`\t\t`}<span className='string'>'jira'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'git'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'webpack'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'figma'</span>
                                {`\n\t`}{`],`}

                                {`\n\t`}<span className='property'>hobbies</span>:{`     `}{`[`}
                                {`\n\t`}{`\t\t`}<span className='string'>'workout'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'design'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'music'</span>,
                                {`\n\t`}{`\t\t`}<span className='string'>'movies'</span>
                                {`\n\t`}{`],`}

                                {`\n\t`}<span className='property'>resume</span>:{`   `}<span className='string'>'</span><a className='link' href="https://docs.google.com/document/d/1tExMkQBO88KnQqWXLJOxSb0LHCbotX320rVWGj7tltc/edit?usp=sharing">resume.pdf</a><span className='string'>'</span>,
                                {`\n\t`}<span className='property'>email</span>:{`    `}<span className='string'>'</span><a className='link' href="mailto:tbn.techno@gmail.com">tbn.techno@gmail.com</a><span className='string'>'</span>
                                {`\n}`}
                            </code>
                        </pre>
                    </Box>
                </div>
            </div>
        </Container>
    </div>
}