import React,{useState, useEffect} from 'react';
import About    from './components/About'
import Home     from './components/Home'
import Projects from './components/Projects'
import Contact  from './components/Contact'
import Nav      from '../components/Nav/Nav'
import './Main.scss'

export default function Main(){
    return <div>
        <Nav/>
        <Home/>
        <About/>
        <Projects/>
        <Contact/>
    </div>
}